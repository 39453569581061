




























































































































// Custom Components
import AnalyticsInvoiceItem from "@/components/reporting-analytics/data/AnalyticsInvoiceItem.vue";
import DateRangeSelector from "@/components/forms/reporting-analytics/DateRangeSelector.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import InvoiceListTable from "@/components/reporting-analytics/data/InvoiceListTable.vue";
import InvoiceAnalyticsSkeleton from "@/components/skeletons/InvoiceAnalyticsSkeleton.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";

// Models
import { InvoiceListData } from "@/models/reporting-analytics/invoice-list-data";

// Services
import { reportingAnalyticsService } from "@/services/reporting-analytics.service";

// Shared
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";

// Store
import InvoicesAnalyticsStore from "@/store/reporting-analytics/invoices.store";

// Third Party Libs
import { getModule } from "vuex-module-decorators";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    "date-range-selector": DateRangeSelector,
    "icon-button": IconButton,
    "analytics-invoice-item": AnalyticsInvoiceItem,
    "invoice-list-table": InvoiceListTable,
    "simple-confirmation-modal": SimpleConfirmationModal,
    skeleton: InvoiceAnalyticsSkeleton
  }
})
export default class InvoiceAnalytics extends Vue {
  // store module
  private invoicesAnalyticsStore: InvoicesAnalyticsStore = getModule(
    InvoicesAnalyticsStore,
    this.$store
  );

  // reactive class properties
  private invoiceListLoading = false;
  private invoiceListFiltersModal = false;
  private supplierTextFiler = "";
  private supplierIdFilter = "";
  private scannedDateFilter = "";
  private invoiceStatusFilter = "";
  private isFetchingItem = false;

  // computed properties
  private get dateRange() {
    return this.invoicesAnalyticsStore.getDateRange;
  }
  private get selectedInvoice() {
    return this.invoicesAnalyticsStore.getSelectedInvoice;
  }
  private get selectedInvoicePdfLocation() {
    return this.invoicesAnalyticsStore.getSelectedInvoicePdfLocation;
  }
  private get invoiceListData() {
    return this.invoicesAnalyticsStore.getInvoiceListData;
  }
  private get searchButtonClicked() {
    return this.invoicesAnalyticsStore.getSearchButtonClicked;
  }
  private get invoiceListDisplayed() {
    return this.invoiceListData.filter((invoice: InvoiceListData) => {
      return (
        invoice.supplierIDText
          .toLowerCase()
          .indexOf(this.supplierTextFiler.toLowerCase()) > -1 &&
        invoice.supplierID
          .toLowerCase()
          .indexOf(this.supplierIdFilter.toLowerCase()) > -1 &&
        invoice.invScannedDate.indexOf(this.scannedDateFilter) > -1 &&
        invoice.invStatus
          .toLowerCase()
          .indexOf(this.invoiceStatusFilter.toLowerCase()) > -1
      );
    });
  }

  // methods
  private openInvoiceListFiltersModal() {
    this.invoicesAnalyticsStore.updateSelectedInvoice(null);
    this.invoiceListFiltersModal = true;
  }

  private handleFetchImage(fetching: boolean) {
    this.isFetchingItem = fetching;
  }

  private removeSearchFilters() {
    this.supplierTextFiler = "";
    this.supplierIdFilter = "";
    this.scannedDateFilter = "";
    this.invoiceStatusFilter = "";
    this.invoiceListFiltersModal = false;
  }

  private applyInvoiceListFilters() {
    this.invoiceListFiltersModal = false;
  }

  async handleDateRangeChange(selection: {
    startDate: string;
    endDate: string;
  }) {
    this.invoicesAnalyticsStore.updateDateRange(selection);
    this.invoicesAnalyticsStore.updateSelectedInvoice(null);
    await this.refreshInvoiceList();
  }

  @Catch((error: any, context: any) => {
    context.invoiceListLoading = false;
    context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async refreshInvoiceList() {
    this.invoiceListLoading = true;

    const invoiceListData: InvoiceListData[] = await reportingAnalyticsService.loadInvoiceListAsync(
      this.dateRange.startDate,
      this.dateRange.endDate,
      "scanned"
    );
    this.invoicesAnalyticsStore.updateInvoiceListData(invoiceListData);

    this.invoicesAnalyticsStore.clickSearchButton();

    this.invoiceListLoading = false;
  }
}
