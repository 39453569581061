





















import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { InvoiceListData } from "@/models/reporting-analytics/invoice-list-data";
import RecognitionAnalyticsStore from "@/store/reporting-analytics/recognition-analytics.store";
import InvoicesAnalyticsStore from "@/store/reporting-analytics/invoices.store";
import { documentDetailsService } from "@/services/document-details.service";
import Catch from "@/shared/decorators/catch-errors";

@Component
export default class AnalyticsInvoiceItem extends Vue {
  // readonly copies of store modules
  private recognitionAnalyticsStore: RecognitionAnalyticsStore = getModule(
    RecognitionAnalyticsStore,
    this.$store
  );
  private invoicesAnalyticsStore: InvoicesAnalyticsStore = getModule(
    InvoicesAnalyticsStore,
    this.$store
  );

  // props passed to this component
  @Prop() invoice!: InvoiceListData;
  @Prop() storeModuleToUse!: string;

  // computed properties
  private get storeModule() {
    if (this.storeModuleToUse === "recognitionAnalytics") {
      return this.recognitionAnalyticsStore;
    } else {
      return this.invoicesAnalyticsStore;
    }
  }
  private get selectedInvoice() {
    return this.storeModule.getSelectedInvoice;
  }

  // methods
  isInvoiceSelected(id: string) {
    if (this.selectedInvoice != null) {
      return this.selectedInvoice.invID === id;
    } else {
      return false;
    }
  }
  @Catch((error: any, context: any) => {
    context.storeModule.updateSelectedInvoicePdfLocation(""); // hides doc-viewer
  })
  async updateInvoiceImageDisplayed(invoice: InvoiceListData) {
    const pdfLocation: ArrayBuffer = await documentDetailsService.loadDocumentImage(
      invoice.invID
    );
    this.storeModule.updateSelectedInvoicePdfLocation(pdfLocation);
  }

  async changeSelectedInvoice(invoice: InvoiceListData) {
    this.storeModule.updateSelectedInvoice(invoice);
    this.$emit("isFetchingImage", true);
    await this.updateInvoiceImageDisplayed(invoice);
    this.$emit("isFetchingImage", false);
  }
}
