





















import { Vue, Component, Prop } from "vue-property-decorator";
import { InvoiceListData } from "@/models/reporting-analytics/invoice-list-data";
@Component
export default class InvoiceListTable extends Vue {
  @Prop() selectedInvoice!: InvoiceListData;
  isFailed(m: string | number) {
    return m === 0 || m === "0";
  }
}
