import { app } from "@/main";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";
import { FieldCoordinates } from "@/models/document-entry/pdf-coordinates";
import { line } from "@/models/document-entry/line";

class DocumentDetailsService {
  private getDocumentImage = async (invoiceId: number | string) => {
    const apiResponse = await app.$ravenapi.get(`api/image/${invoiceId}`);
    return apiResponse?.data?.data;
  };

  public loadDocumentImage = async (
    invoiceId: number | string
  ): Promise<ArrayBuffer> => {
    const results: any = await this.getDocumentImage(invoiceId);
    return results;
  };

  private getImageCoordinates = async (invoiceId: number | string) => {
    const apiResponse = await app.$ravenapi.get(
      `api/image/coordinates/${invoiceId}`
    );
    return apiResponse;
  };

  public loadImageCoordinates = async (
    invoiceId: number | string
  ): Promise<FieldCoordinates[]> => {
    const results: any = await this.getImageCoordinates(invoiceId);
    return results;
  };

  public deleteImageCoordinates = async (
    invID: number | string,
    fieldName: string
  ) => {
    const apiResponse = await app.$ravenapi.delete(
      `api/image/coordinates/delete/${invID}/${fieldName}Location`
    );
    return apiResponse;
  };

  private getInitialDocumentDetails = async (invoiceId: number | string) => {
    const apiResponse: any = await app.$ravenapi.get(
      `api/invoice/${invoiceId}`
    );
    return apiResponse;
  };

  public loadDocumentDetails = async (invoiceId: number | string) => {
    const results = await this.getInitialDocumentDetails(invoiceId);
    if (results.data?.statusCode === 400 || results.data?.statusCode === 404) {
      checkCurrentRouteAndRedirect(app.$router, "/404/document");
      return null;
    }
    // otherwise return results
    return results;
  };

  private getDocumentLinesAsync = async (invoiceId: number | string) => {
    const apiResponse: any = await app.$ravenapi.get(
      `api/invoice/getlines/${invoiceId}`
    );
    return apiResponse;
  };

  public loadDocumentLinesAsync = async (invoiceId: number | string) => {
    const results = await this.getDocumentLinesAsync(invoiceId);
    results?.lines?.forEach(
      (invoiceLine: line) => (invoiceLine.isActive = false)
    );

    return results?.lines;
  };

  public regenerateEDIDocumentImage = async (invID: number | string) => {
    return await app.$ravenapi.post(`api/image/${invID}`);
  };
}

export const documentDetailsService = new DocumentDetailsService();
